// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const SOCIALS = [
  {
    value: 'instagram',
    name: 'Instagram',
    icon: 'mdi:instagram',
    color: '#E02D69',
    path: 'https://www.instagram.com/horizonbakery',
  },
  {
    value: 'xiaohongshu',
    name: 'Xiaohongshu',
    localfile: '/assets/icons/social/xiaohongshu.png',
    color: '#ff2e4d',
    path: 'https://www.xiaohongshu.com/user/profile/5af63413b1da1426c395dbe2',
  },
  {
    value: 'wechat',
    name: 'WeChat',
    icon: 'ic:baseline-wechat',
    color: '#2DBB00',
    path: paths.contact,
  },
];

const LINKS = [
  {
    headline: 'Horizon Bakery',
    children: [
      { name: 'Cake gallery', href: paths.gallery.root },
      { name: 'Pricing', href: paths.pricing },
      { name: 'FAQs', href: paths.faqs },
      { name: 'Contact us', href: paths.contact },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'orders@horizonbakery.com', href: 'mailto:orders@horizonbakery.com' },
      {
        name: '19929 Stevens Creek Blvd, Cupertino, CA 95014',
        href: 'https://maps.app.goo.gl/cvRzwZ4jCSWcR5dt5',
      },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const pathname = usePathname();

  const isHome = pathname === '/';

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography variant="caption" component="div">
          © All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Logo sx={{ mb: 3 }} />

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={8} md={3}>
            <Typography
              variant="body2"
              sx={{
                maxWidth: 270,
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              Based in the San Francisco Bay Area, we aim to produce fresh, tasty, light sweet cakes
              that are memorable for any of your occasions.
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {SOCIALS.map((social) => (
                <IconButton
                  key={social.name}
                  sx={{
                    '&:hover': {
                      bgcolor: alpha(social.color, 0.08),
                    },
                  }}
                  href={social.path}
                  target="_blank"
                  rel="noopener"
                >
                  {social.icon && <Iconify color={social.color} icon={social.icon} />}
                  {social.localfile && <img src={social.localfile} alt={social.name} width={20} />}
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="body2" sx={{ mt: 10 }}>
          © 2024. All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  return isHome ? simpleFooter : mainFooter;
}
