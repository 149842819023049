import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const SquareContext = createContext({});

export const useSquareContext = () => {
  const context = useContext(SquareContext);

  if (!context) throw new Error('useSquareContext must be use inside SquareProvider');

  return context;
};
