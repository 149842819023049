import { useMemo } from 'react';
import useSWR from 'swr';
// utils
import { fetcher, endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

const URL = endpoints.slots;

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
};

export function useGetSlotsInMonth(year, month) {
  const url = `${URL}/${year}/${month}`;
  const { data, isLoading, error, isValidating } = useSWR(url, fetcher, options);

  const memoizedValue = useMemo(() => {
    const slots = data?.slotsDetails || [];

    return {
      slots,
      slotsLoading: isLoading,
      slotsError: error,
      slotsValidating: isValidating,
      slotsEmpty: !isLoading && Object.keys(slots).length === 0,
    };
  }, [data?.slotsDetails, error, isLoading, isValidating]);

  return memoizedValue;
}

export function useGetSlotsInDate(year, month, day) {
  const url = `${URL}/${year}/${month}/${day}`;
  const { data, isLoading, error, isValidating } = useSWR(url, fetcher, options);

  const memoizedValue = useMemo(() => {
    const slots = data?.slotsDetails || {};

    return {
      slots,
      slotsLoading: isLoading,
      slotsError: error,
      slotsValidating: isValidating,
      slotsEmpty: !isLoading && Object.keys(slots).length === 0,
    };
  }, [data?.slotsDetails, error, isLoading, isValidating]);

  return memoizedValue;
}
