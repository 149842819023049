import PropTypes from 'prop-types';
import { useMemo, useState, useEffect } from 'react';
import { SQUARE_API } from 'src/config-global';
import { SquareContext } from './square-context';

export function SquareProvider({ children }) {
  const [isLoad, setLoad] = useState(false);
  useEffect(() => {
    const sqPaymentScript = document.createElement('script');

    sqPaymentScript.src = SQUARE_API.sdkAddress;
    sqPaymentScript.type = 'text/javascript';
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      setLoad(true);
    };
    document.getElementsByTagName('head')[0].appendChild(sqPaymentScript);
  });

  const memoizedValue = useMemo(
    () => ({
      isLoad,
      applicationId: SQUARE_API.applicationId,
      locationId: SQUARE_API.locationId,
      scriptSrc: SQUARE_API.sdkAddress,
    }),
    [isLoad]
  );

  return <SquareContext.Provider value={memoizedValue}>{children}</SquareContext.Provider>;
}

SquareProvider.propTypes = {
  children: PropTypes.node,
};
