import { useMemo } from 'react';
import useSWR from 'swr';
// utils
import { fetcher, endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

const URL = endpoints.defaultSetting;

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
};

export function useGetDefaultSetting() {
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(() => {
    const defaultSetting = data?.defaultSetting || {};

    return {
      defaultSetting,
      defaultSettingLoading: isLoading,
      defaultSettingError: error,
      defaultSettingValidating: isValidating,
      defaultSettingEmpty: !isLoading && Object.keys(defaultSetting).length === 0,
    };
  }, [data?.defaultSetting, error, isLoading, isValidating]);

  return memoizedValue;
}
