import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const ReservationDateContext = createContext({});

export const useReservationDateContext = () => {
  const context = useContext(ReservationDateContext);

  if (!context)
    throw new Error('useReservationDateContext must be use inside ReservationDateProvider');

  return context;
};
