import { Navigate, useRoutes } from 'react-router-dom';
import { lazy } from 'react';
// layouts
import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { PATH_AFTER_LOGIN, MAINTENANCE_MODE } from 'src/config-global';
import { mainRoutes, HomePage } from './main';
import { maintenanceRoutes } from './maintenance';

// ----------------------------------------------------------------------

export default function Router() {
  let routes = [];
  if (MAINTENANCE_MODE === 'true') {
    routes = [...maintenanceRoutes, { path: '*', element: <Navigate to="/maintenance" replace /> }];
  } else {
    routes = [
      // SET INDEX PAGE WITH SKIP HOME PAGE
      // {
      //   path: '/',
      //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      // },

      // ----------------------------------------------------------------------

      // SET INDEX PAGE WITH HOME PAGE
      // {
      //   path: '/',
      //   element: <HomePage />,
      // },

      // Main routes
      ...mainRoutes,
      // No match 404
      { path: '*', element: <Navigate to="/404" replace /> },
    ];
  }
  return useRoutes(routes);
}
