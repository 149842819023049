import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
const PageMaintenance = lazy(() => import('src/pages/maintenance'));

// ----------------------------------------------------------------------

export const maintenanceRoutes = [
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '/', element: <PageMaintenance /> },
      { path: 'maintenance', element: <PageMaintenance /> },
    ],
  },
];
