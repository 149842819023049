import PropTypes from 'prop-types';
import { useState, useCallback, useMemo, useEffect } from 'react';
// API
import { useGetSlotsInDate, useGetSlotsInMonth } from 'src/api/slots';
import { useGetDefaultSetting } from 'src/api/default-setting';
// utils
import { fDate } from 'src/utils/format-time';

import { ReservationDateContext } from './reservation-date-context';

export function ReservationDateProvider({ children }) {
  const dateAfterTomorrow = useMemo(() => {
    const date = new Date();
    return new Date(date.setDate(date.getDate() + 2));
  }, []);

  const { defaultSetting, defaultSettingLoading, defaultSettingError } = useGetDefaultSetting();

  const [values, setValues] = useState({
    firstSelectableDate: dateAfterTomorrow,
    lastSelectableDate: dateAfterTomorrow,
    reservationDate: dateAfterTomorrow,
    daySlotsDetails: {},
    daySlotsLoading: true,
    browseMonth: dateAfterTomorrow,
    monthSlotsDetails: [],
    monthSlotsLoading: true,
    loadingError: false,
  });

  const setValue = useCallback(
    (name, value) => {
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setValues]
  );

  const {
    slots: slotsInMonth,
    slotsLoading: slotsInMonthLoading,
    slotsError: slotsInMonthError,
  } = useGetSlotsInMonth(fDate(values.browseMonth, 'yyyy'), fDate(values.browseMonth, 'LL'));

  const {
    slots: slotsInDate,
    slotsLoading: slotsInDateLoading,
    slotsError: slotsInDateError,
  } = useGetSlotsInDate(
    fDate(values.reservationDate, 'yyyy'),
    fDate(values.reservationDate, 'LL'),
    fDate(values.reservationDate, 'dd')
  );

  // Initialize future days
  useEffect(() => {
    const lastSelectableDate = new Date(dateAfterTomorrow);
    lastSelectableDate.setDate(lastSelectableDate.getDate() + (defaultSetting?.futureDays ?? 0));
    setValue('lastSelectableDate', lastSelectableDate);
  }, [setValue, defaultSetting, dateAfterTomorrow]);

  // Set monthSlotsLoading
  useEffect(() => {
    setValue('monthSlotsLoading', slotsInMonthLoading || defaultSettingLoading);
  }, [setValue, slotsInMonthLoading, defaultSettingLoading]);

  // Set daySlotsLoading
  useEffect(() => {
    setValue('daySlotsLoading', slotsInDateLoading);
  }, [setValue, slotsInDateLoading]);

  // Set loading error
  useEffect(() => {
    setValue('loadingError', slotsInDateError || slotsInMonthError || defaultSettingError);
  }, [setValue, slotsInDateError, slotsInMonthError, defaultSettingError]);

  // Set month slots details
  useEffect(() => {
    setValue('monthSlotsDetails', slotsInMonth);
  }, [setValue, slotsInMonth]);

  // Set date slots details
  useEffect(() => {
    setValue('daySlotsDetails', slotsInDate);
  }, [setValue, slotsInDate]);

  const onSelectDate = useCallback(
    (newDate) => {
      setValue('reservationDate', newDate);
    },
    [setValue]
  );

  const onBrowseMonth = useCallback(
    (newMonth) => {
      setValue('browseMonth', newMonth);
    },
    [setValue]
  );

  const memoizedValue = useMemo(
    () => ({
      ...values,
      onSelectDate,
      onBrowseMonth,
    }),
    [values, onSelectDate, onBrowseMonth]
  );

  return (
    <ReservationDateContext.Provider value={memoizedValue}>
      {children}
    </ReservationDateContext.Provider>
  );
}

ReservationDateProvider.propTypes = {
  children: PropTypes.node,
};
