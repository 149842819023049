// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Gallery',
    icon: <Iconify icon="ic:round-cake" />,
    path: paths.gallery.root,
  },
  {
    title: 'Pricing',
    icon: <Iconify icon="solar:tag-price-bold" />,
    path: paths.pricing,
  },
  {
    title: 'FAQs',
    icon: <Iconify icon="wpf:faq" />,
    path: paths.faqs,
  },
  {
    title: 'Contact us',
    icon: <Iconify icon="ic:baseline-message" />,
    path: paths.contact,
  },
];
